<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">Pelapor</span>
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <div class="tab-content">
        <!--begin::Table-->
        <div class="table-responsive">
          <table
            class="
              table
              table-head-custom
              table-vertical-center
              table-head-bg
              table-borderless
            "
          >
            <thead>
              <tr class="text-left">
                <th style="width: 20px">NO</th>
                <th style="min-width: 620px">Nama Kegiatan</th>
                <th style="min-width: 100px">Aksi</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in list">
                <tr v-bind:key="i">
                  <td>
                    <span
                      class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                      >{{ item.paid }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                      >{{ item.amount }}</span
                    >
                  </td>
                  <td class="pr-0 text-center">
                    <a class="btn btn-info font-weight-bolder font-size-sm mr-3"
                      ><i class="flaticon2-pen"></i>Edit</a
                    >
                    <button
                      v-on:click="submit"
                      class="btn btn-danger font-weight-bolder font-size-sm"
                      data-wizard-type="action-submit"
                    >
                      <i class="flaticon2-trash"></i>Hapus
                    </button>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
export default {
  name: "Draft-pelapor",
  data() {
    return {
      list: [
        {
          paid: "1",
          amount: "Lorem ipsum dolor sit amet consectetur",
        },
        {
          paid: "2",
          amount: "Lorem ipsum dolor sit amet consectetur",
        },
        {
          paid: "3",
          amount: "Lorem ipsum dolor sit amet consectetur",
        },
        {
          paid: "4",
          amount: "Lorem ipsum dolor sit amet consectetur",
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Wizard", route: "wizard-1" },
      { title: "Wizard-3" },
    ]);
  },
  methods: {
    submit: function (e) {
      e.preventDefault();
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },
  },
};
</script>
<style>
.table.table-head-bg thead tr,
.table.table-head-bg thead th {
  background-color: #333333;
}
.table.table-head-custom thead tr,
.table.table-head-custom thead th {
  color: #ffffff !important;
  text-align: center;
}
.table.table-head-custom tbody tr,
.table.table-head-custom tbody td {
  text-align: center;
}
</style>
