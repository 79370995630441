<template>
  <div>
    <h2 class="text-center">Draft</h2>
    <div class="row">
      <div class="col">
        <Draftpemohon />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <Draftpelapor />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Draftpemohon from "@/view/content/widgets/advance-table/Draft-pemohon.vue";
import Draftpelapor from "@/view/content/widgets/advance-table/Draft-pelapor.vue";
export default {
  name: "Draft",
  components: {
    Draftpemohon,
    Draftpelapor,
  },
  methods: {},
  computed: {
    ...mapGetters(["layoutConfig"]),
    dataToShow() {
      if (this.show === "month") return this.month;
      if (this.show === "week") return this.week;
      if (this.show === "day") return this.day;
      return this.day;
    },
  },
};
</script>
